import { Injectable } from '@angular/core';
import { User } from '../models/user.model';
import { StatusId } from '../models/status.model';
import { AvailableRoles, RoleId } from '../models/role.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/angular';
import { ApiService } from './api.service';
import { SocketService } from './socket.service';
import { Router } from '@angular/router';
import * as JwtDecode from 'jwt-decode';
import { after } from 'node:test';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  public user: User;
  public loginRedirectUrl: string;
  private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);

  constructor(private apiService: ApiService, private router: Router) {
    const self: PermissionsService = this;

    this.apiService.getFeathers().hooks({
      async error(context: any) {
        if (context.error.code === 401) {
          if (context.event !== 'created') {
            await self.logout();
          }
        }
      }
    });
  }

  public async authenticate(credentials?: any): Promise<boolean> {
    try {
      const response = await this.apiService.authenticate(credentials);
      this.setUser(JwtDecode(response.accessToken));
      return true;
    } catch (err) {
      throw err;
    }
  }

  public setUser(user: User): void {
    this.user = user;

    if (environment.sentry.active && user && user.roleIds) {
      Sentry.setUser({
        id: user.id ? user.id.toString() : null,
        username: user.fullName,
        email: user.email,
        roles: user.roleIds.map((id) => AvailableRoles.find((r) => r.id === id).name)
      });
    }

    this.userSubject.next(user);
  }

  public onUser(): Observable<User> {
    return this.userSubject.asObservable();
  }

  public async logout(): Promise<void> {
    await this.apiService.logout();

    this.setUser(null);

    if (environment.sentry) {
      Sentry.setUser(null);
    }

    await this.router.navigate(['/login']);
  }

  public restrictToRoles(
    roleIds: number | number[],
    includeAdmin: boolean = true,
    includeSuperadmin: boolean = true
  ): boolean {
    if (!this.user || !this.user.roleIds) {
      return false;
    }

    if (roleIds instanceof Array) {
      for (const roleId of roleIds) {
        if (this.user.roleIds.includes(roleId)) {
          return true;
        }
      }
    } else if (this.user.roleIds.includes(roleIds)) {
      return true;
    }

    // Allow admin
    if (includeAdmin && this.user.roleIds.includes(RoleId.ADMIN)) {
      return true;
    }

    // Allow superadmin
    if (includeSuperadmin && this.user.roleIds.includes(RoleId.SUPERADMIN)) {
      return this.user.roleIds.includes(RoleId.SUPERADMIN);
    }
  }

  public restrictFromRoles(roleIds: number | number[]): boolean {
    if (!this.user) {
      return false;
    }

    if (this.user.roleIds) {
      if (roleIds instanceof Array) {
        for (const roleId of roleIds) {
          if (this.user.roleIds.includes(roleId)) {
            return false;
          }
        }
      } else if (this.user.roleIds.includes(roleIds)) {
        return false;
      }
    }

    return true;
  }

  public isOuthouseManager(): boolean {
    return this.user.managerInIds && this.user.managerInIds.length > 0;
  }

  public isDesignClaimable(statusId: StatusId, assignee: User): boolean {
    return (
      this.restrictToRoles([
        RoleId.OUTHOUSE_DESIGNER,
        RoleId.STICKER_DESIGNER,
        RoleId.INHOUSE_DESIGNER,
        RoleId.SUBLIMATION_DESIGNER,
        RoleId.MID_TIER_DESIGNER
      ]) &&
      [StatusId.AWAITING_DESIGN, StatusId.DESIGN_REJECTED, StatusId.IN_DESIGN].includes(statusId) &&
      (!assignee || assignee.id !== this.user.id)
    );
  }

  public isDesignReviewClaimable(statusId: StatusId, assignee: User): boolean {
    return (
      this.restrictToRoles([
        RoleId.INHOUSE_DESIGNER,
        RoleId.SUBLIMATION_REVIEWER,
        RoleId.STICKER_REVIEWER,
        RoleId.OUTHOUSE_REVIEWER
      ]) &&
      [StatusId.AWAITING_DESIGN_REVIEW, StatusId.IN_REVIEW].includes(statusId) &&
      (!assignee || assignee.id !== this.user.id)
    );
  }

  public isAllowedToViewUsers(): boolean {
    return (
      this.restrictToRoles([
        RoleId.ORDER_MANAGER,
        RoleId.ACCOUNT_REP,
        RoleId.CUSTOMER_SERVICE_MANAGER,
        RoleId.CUSTOMER_SERVICE,
        RoleId.PRINT_MANAGER,
        RoleId.RETAIL_MANAGER,
        RoleId.BULK_DESIGNER,
        RoleId.HR
      ]) || this.isOuthouseManager()
    );
  }
}
