import { Injectable } from '@angular/core';
import { feathers } from '@feathersjs/feathers';
import { environment } from '../../environments/environment';
import socketio from '@feathersjs/socketio-client';
import { io, type Socket } from 'socket.io-client';
import authentication from '@feathersjs/authentication-client';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private readonly feathers = feathers();
  private socket: Socket;

  constructor(private apiService: ApiService) {
    const self = this;

    this.socket = io(environment.apiUrl, {
      transports: ['websocket'],
      upgrade: false
    });
    this.feathers.configure(socketio(this.socket));

    this.socket.on('connect', async () => {
      this.authenticate();
    });

    this.apiService.getFeathers().hooks({
      async after(context: any) {
        if (context.event === 'created' && context.result.accessToken) {
          self.authenticate(context.result.accessToken);
        }
      }
    });
  }

  public async authenticate(token?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const doAuthentication = async (): Promise<any> => {
        if (!token) {
          token = await this.apiService.getFeathers().authentication.getAccessToken();
        }

        if (!token) {
          return resolve();
        }

        return new Promise((resolve, reject) => {
          this.socket.emit(
            'create',
            'authentication',
            {
              strategy: 'jwt',
              accessToken: token
            },
            function (error, newAuthResult) {
              if (error) {
                console.error(error);
                return reject(error);
              }

              return resolve(newAuthResult);
            }
          );
        });
      };

      if (!this.socket.connected) {
        const socket = io(environment.apiUrl, {
          transports: ['websocket'],
          upgrade: false
        });

        socket.on('connect', async () => {
          this.socket = socket;
          resolve(await doAuthentication());
        });
      } else {
        resolve(await doAuthentication());
      }
    });
  }

  /**
   * Get feathers object.
   */
  public getFeathers(): any {
    return this.feathers;
  }

  public getSocket(): any {
    return this.socket;
  }

  /**
   * Get feathers service.
   * @param {string} name The service to get.
   * @returns {any} The feathers service.
   */
  public service(name: string): any {
    return this.feathers.service(name);
  }

  /**
   * Logout a logged in user.
   */
  public logout(): Promise<any> {
    return this.feathers.logout();
  }
}
